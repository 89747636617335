<template>
  <b-card>
    <!-- form -->
    <validation-observer ref="editSerie">
      <b-form
        v-if="seriePrepare"
        @submit.prevent="handleSubmit"
        @keydown.enter="handleSubmit"
      >
        <b-row>
          <b-col cols="12">
            <h4 class="mb-2" >{{ $t('DetallesSerie') }}</h4>
          </b-col>
          <b-col>
            <validation-provider
              #default="{ errors }"
              :name="$t('Nombre')"
              rules="required"
            >
              <b-form-group
                :label="$t('Nombre')"
                label-for="serie-name"
              >
                <b-form-input
                  v-model="serie.name"
                  name="name"
                  :placeholder="$t('Nombre')"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col sm="12">
            <validation-provider
                #default="{ errors }"
                :name="$t('Descripcion')"
                rules=""
            >
              <b-form-group
                  :label="$t('Descripcion')"
                  label-for="serie-description"
              >
                <quill-editor
                    v-model="serie.description"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>

          <b-col cols="12 text-right">
            <b-button
              type="submit"
              variant="primary"
              class="mt-2 mr-1"
            >
              {{ $t('Actualizar') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      email,
      seriePrepare: false,
    }
  },
  computed: {
    ...mapGetters({
      serie: 'series/getSerie',
      currentClient: 'clients/getCurrentClient',
    }),
  },
  methods: {
    ...mapActions({
      edit: 'series/edit',
      getSerie: 'series/getSerie',
    }),
    handleSubmit() {
      this.$refs.editSerie.validate().then(success => {
        if (success) {
          const { id } = this.serie
          const formData = this.createFormData()
          if (formData) {
            this.edit({ id, serie: formData })
          }
        }
      })
    },
    createFormData() {
      const data = new FormData()
      data.append('name', this.serie.name)
      data.append('description', this.serie.description)

      return data
    },
  },
  async created() {
    await this.getSerie(this.$route.params.id)
    this.seriePrepare = true
  },
}
</script>

<style lang="scss">
</style>
